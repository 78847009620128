<template>
  <div class="about">
    <div class="site-content">
      <div class="content-warp">
        <div class="about-site about-info">
          <section-title><span>❤</span>联系作者</section-title>
          <div class="info-card">
            <blockquote class="blockquote">
              <p>长风破浪会有时，直挂云帆济沧海</p>
            </blockquote>
            <img src="@/assets/img/avatar.jpg" style="width: 50px; height: 50px; border-radius: 50%" alt="" srcset="" />
            <ul style="list-style-type: disc; margin-left: 16px">
              <li style="margin-top: 10px">大家好，我是Eric，一个热爱探索技术深度与广度的程序员。</li>
              <li style="margin-top: 10px">在这里，我不仅分享前端开发技术的最新趋势，比如React、Vue等框架的实战心得。</li>
              <li style="margin-top: 10px">还深入探讨后端开发技术，如Node.js、NestJs、express、Mysql等服务端编程的优化策略。</li>
              <li style="margin-top: 10px">每一篇文章都是我学习之旅的印记，旨在为同道中人提供灵感与解决方案。欢迎志同道合的朋友一起交流，共同成长。</li>
              <li style="margin-top: 10px">有什么优化建议或者页面有bug可以反馈给我,联系方式见最下方,感谢！</li>
              <li style="margin-top: 10px">另外我的微信：wx92642023</li>
            </ul>
          </div>
        </div>
        <div class="about-me about-info">
          <section-title id="Guestbook"><span>❤</span>给我留言</section-title>
          <div class="info-card">
            <div class="contactForm">
              <div class="form-item">
                <label for="mail">邮箱</label>
                <input class="v" type="email" name="mail" id="mail" v-model="form.mailbox" />
              </div>
              <div class="form-item">
                <label for="content">内容</label>
                <textarea class="v" id="content" name="content" v-model="form.content" maxlength="255"></textarea>
              </div>
              <div class="form-item">
                <label></label>
                <button @click="setContac">提交</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sectionTitle from '@/components/section-title'
export default {
  name: 'About',
  data() {
    return {
      list: [],
      form: {
        mailbox: '',
        content: ''
      }
    }
  },
  components: {
    sectionTitle
  },
  methods: {
    async setContac() {
      var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      if (!myreg.test(this.form.mailbox)) {
        alert('提示\n\n请输入有效的E_mail！')
        return
      } else if (!this.form.content) {
        alert('请输入内容')
      } else {
        var res = await this.$http.setUsrPosition(this.form)
        console.log(res)
        if (res.code == 200) {
          this.$message({
            message: '留言成功',
            type: 'success'
          })
          this.form.content = ''
          this.form.mailbox = ''
        }
      }
    }
  },
  mounted() {}
}
</script>
<style scoped lang="less">
.about {
  padding-top: 40px;
}
blockquote {
  margin: 1rem 0;
  padding: 0.25rem 0 0.25rem 1rem;
  color: #999;
  font-size: 1rem;
  border-left: 0.2rem solid #dfe2e5;
}
.content-warp {
  margin-top: 80px;

  .about-info {
    margin: 30px 0;

    span {
      color: red;
      margin-right: 10px;
    }

    .info-card {
      min-height: 100px;
      padding: 20px;
      border-radius: 3px;
      margin: 30px 0 50px 0;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      p {
        line-height: 1.7rem;
      }
    }
  }
  .contactForm {
    width: 100%;
    padding: 20px;
    .form-item {
      align-items: center;
      display: flex;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      label {
        width: 80px;
      }
      .v {
        min-height: 40px;
        line-height: 20px;
        border-radius: 3px;
        padding: 2px 10px;
        outline: none;
        border: 1px solid #8fd0cc;
        width: 100%;
        resize: vertical;
      }
      button {
        width: 100px;
        height: 40px;
        border-radius: 3px;
        outline: 0;
        border-style: none;
        cursor: pointer;
        background-color: #409eff;
        color: white;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

/*******/
@media (max-width: 800px) {
  .content-warp {
    margin-top: 0;
  }
}
</style>
